@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #040c19;
}

.ds{
  font-family: 'Montserrat', sans-serif;
}

.main {
  margin: auto 0;
  min-height: 100vh;
  padding: 40px;
}
.text-center {
  text-align: center;
}

.textclr {
  color: #ffffff;
}
.btn {
  width: 307px;
  height: 57px;
  left: calc(50% - 307px / 2 - 1.5px);
  top: 672px;

  background: #6f7dff;
  box-shadow: inset -3px 4px 4px #7885ff, inset 3px -4px 4px #3542bf;
}

.header-name {
  position: absolute;
  height: 20px;
  left: 41.6%;
  right: 41.6%;
  top: 248px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 20px;
  /* identical to box height, or 87% */

  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.form-allign {
  margin: auto;
  max-width: 300px;
}

.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-t-80 {
  margin-top: 80px;
}

.input-text {
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  padding: 10px;
  line-height: 30px;
  background: #040c19;
  width: 100%;
}
.text-left {
  text-align: left;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px;
}

.circle-otp input {
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  background: #dadada26;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid white;
  outline: none;
  color: white;
}
.circle-otp input:focus {
  outline: none;
}

.cursor-ptr {
  cursor: pointer;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix {
  display: inline-block;
}
/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* close commented backslash hack */

.p-l-45 {
  padding-left: 45px;
}
.bgblue {
  background: #6e7dff;
  width: 365px;
  margin: auto;
  min-height: 490px;
  box-shadow: inset -4px -3px 4px #565fb6, inset 4px 6px 4px #9ca6ff;
  border-radius: 8px;
  margin-top: -16px;
}
.credit-limit {
  color: #53ec0c;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 68px;
}

.resend-disabled {
  color: #e5e5e566; 
}
.resend-active {
  color: #6e7dff;
}
.modal-all .custom-mdl {
  border-radius: 11px;
  min-height: 400px;
}
.modal-all .modal-dialog {
  max-width: 300px;
}

.text-wth {
  max-width: 382px;
  margin: 0 auto;
  color: #bababa;
  margin-top: 200px;
}

.kyc-animation-img {
  width: 389px;
  height: 390px;
  margin: -100px;
}

.signin-btn {
  padding: 5px 40px !important;
  border: #3c3c3c33;
  color: white !important;
  background: #040c19 !important;
  border: 1px solid #bcbfcb !important;
  border-radius: 8px !important;
}
.signin-btn>div{
  display: none;
}
.signin-btn>span div{
  color: white;
}

.signin-btn>span{
  padding: 0px !important;
}

.sipner-clr {
  color: white;
}

.res-margin {
  margin-top: 50px;
}

.check-box {
  padding-left: 25px;
  position: relative;
}
.input-checkbox {
  position: absolute;
  top: 5px;
  left: 0;
}
.logo {
  clear: both;
}
.logo-left-img {
  width: 50px;
}
img.kyc-animation-img {
  max-width: 100%;
}
.res-agree-img {
  max-width: 650%;
}
.modal-dialog-centered {
  margin-top: -89px;
}

.p-l-15 {
  padding-left: 15px;
}

/* loader css=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* loader */

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast-container svg {
  display: none;
}

/* <<<=======================================================>>> */
/* #Media  query */

@media only screen and (max-width: 600px) {
  .main {
    padding: 10px;
  }
  .kyc-animation-img {
    width: auto;
    height: auto;
    margin: 0 auto;
  }
  .res-padding {
    padding-left: 15px;
  }
  .text-wth {
    margin-top: 10px;
  }
  .res-agree-img {
    max-width: 100%;
    margin-top: 30px;
  }
  .bgblue {
    max-width: 98%;
    margin-top: 30px;
  }

  .modal-dialog-centered {
    margin: auto;
  }
}
